import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./app.css";

import Location from "./components/Location";
import BottomNav from "./components/BottomNav";
import ScrollToTop from "./components/ScrollToTop";

import { setLocation } from "./actions";
import { setWeather } from "./actions";

const App = () => {
  const a = "3436a533716643b989d191452220402";
  const dispatch = useDispatch();

  let zipCode = useSelector((state) => state.zipCode);
  let weatherData = useSelector((state) => state.weather);

  useEffect(() => {
    if (zipCode != null) {
      getWeather(zipCode);
    }
  }, [zipCode]);

  useEffect(() => {
    if (weatherData != null) {
      dispatch(
        setLocation(
          weatherData.location.name + ", " + weatherData.location.region
        )
      );
    }
  }, [weatherData]);

  const getWeather = async () => {
    let data = await fetch(
      `https://api.weatherapi.com/v1/forecast.json?key=${a}&q=${zipCode}&days=3`
    );
    data = await data.json();
    dispatch(setWeather(data));
  };

  return (
    <div>
      <Location />
      <ScrollToTop />
      <Outlet />
      <BottomNav />
    </div>
  );
};

export default App;
