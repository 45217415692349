import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setGeo,
  setRadarSource,
  setZipCode,
  setLocation,
  setWeather,
} from "../actions";

const Location = () => {
  const a = "AIzaSyBQU8uqZfvXuRF30w6F_Wf8yrP1rz_P8VM";
  const b = "20b5f66aaec3e1a52faa0b715f27d988afc01302";
  const dispatch = useDispatch();

  let geo = useSelector((state) => state.geo);
  let location = useSelector((state) => state.location);

  const [newLocation, setNewLocation] = useState(location);

  useEffect(() => {
    if (location === "Loading...") {
      getGeo();
    }
  }, []);

  useEffect(() => {
    if (geo != null && geo.lat != null) {
      dispatch(
        setRadarSource(
          `https://embed.windy.com/embed2.html?lat=${geo.lat}&lon=${geo.long}&width=${window.innerWidth}&height=${window.innerHeight}&zoom=8&level=surface&overlay=radar&product=radar&calendar=now&type=map&location=coordinates&metricWind=mph&metricTemp=&radarRange=-1`
        )
      );
      fetchZipCodeByCurrentPosition();
    }
  }, [geo]);

  useEffect(() => {
    if (newLocation !== location) {
      setNewLocation(location);
    }
  }, [location]);

  const getGeo = async () => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        dispatch(
          setGeo({ lat: pos.coords.latitude, long: pos.coords.longitude })
        );
      },
      () => {
        getZipCodeByIp();
      }
    );
  };

  const getZipCodeByIp = async () => {
    let data = await fetch(
      `https://api.getgeoapi.com/v2/ip/check?api_key=${b}&format=json`
    );
    data = await data.json();
    if (data.postcode) {
      dispatch(
        setGeo({ lat: data.location.latitude, long: data.location.longitude })
      );
    }
  };

  const fetchZipCodeByCurrentPosition = async () => {
    try {
      let data = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${geo.lat},${geo.long}&result_type=postal_code&key=${a}`
      );
      data = await data.json();
      if (data.results.length > 0) {
        dispatch(setZipCode(data.results[0].address_components[0].long_name));
      }
    } catch (err) {}
  };

  const resetToGeo = () => {
    hideLocationSelector();
    dispatch(setLocation("Loading..."));
    dispatch(setZipCode(null));
    dispatch(setWeather(null));
    getGeo();
  };

  const showLocationSelector = () => {
    const classList = document.querySelector(".dropdown-content").classList;
    classList.remove("retracted");
    classList.add("dropped");
  };

  const hideLocationSelector = () => {
    const classList = document.querySelector(".dropdown-content").classList;
    classList.remove("dropped");
    classList.add("retracted");
  };

  const enterLocation = (event) => {
    if (event.keyCode === 13) {
      submitNewLocation();
    }
  };

  const submitNewLocation = async () => {
    hideLocationSelector();
    if (location === newLocation) return;
    dispatch(setLocation("Loading..."));
    dispatch(setZipCode(null));
    dispatch(setWeather(null));
    try {
      let data = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${newLocation}&key=${a}`
      );
      data = await data.json();
      if (data.results.length > 0) {
        dispatch(
          setGeo({
            lat: data.results[0].geometry.location.lat,
            long: data.results[0].geometry.location.lng,
          })
        );
      } else {
        dispatch(setLocation("Unknown location..."));
      }
    } catch (err) {}
  };

  return (
    <div>
      <div className="dropdown-container">
        <div className="dropdown-content">
          <div id="xdiv">
            <div className="xdiv" onClick={hideLocationSelector}>
              <div className="xd"></div>
            </div>
          </div>
          <div className="cursor" onClick={resetToGeo}>
            <i id="cursor-icon" className="bi bi-cursor-fill"></i>
          </div>
          <div className="dropdown-content-title">Location</div>
          <div>
            <input
              id="location-search"
              type="text"
              value={newLocation}
              onClick={(e) => e.target.select()}
              onKeyUp={enterLocation}
              onChange={(e) => setNewLocation(e.target.value)}
            />
          </div>
          <button
            id="location-search-btn"
            className="search-btn"
            onClick={submitNewLocation}
          >
            Get Weather
          </button>
        </div>
      </div>
      <div className="geo">
        <div onClick={showLocationSelector} className="city">
          {newLocation}&nbsp;
          <i className="bi bi-caret-down-fill"></i>
        </div>
      </div>
    </div>
  );
};

export default Location;
