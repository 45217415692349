import { combineReducers } from "redux";
import geoReducer from "./geoReducer";
import locationReducer from "./locationReducer";
import zipcodeReducer from "./zipcodeReducer";
import radarReducer from "./radarReducer";
import weatherReducer from "./weatherReducer";

export default combineReducers({
  geo: geoReducer,
  location: locationReducer,
  zipCode: zipcodeReducer,
  radar: radarReducer,
  weather: weatherReducer,
});
