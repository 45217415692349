import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const DailyWeather = () => {
  let weatherData = useSelector((state) => state.weather);

  const [days, setDays] = useState([]);

  useEffect(() => {
    if (weatherData != null) {
      setDays(weatherData.forecast.forecastday);
    }
  }, [weatherData]);

  const renderedDays = days.map((day) => {
    let date = new Date(day.date.replace(/-/g, "/"));
    return (
      <div className="daily-entry" key={day.date}>
        <div className="daily-weather-date">{format(date, "EEE, LLL d")}</div>
        <div className="daily-weather-icon">
          <img id="daily-weather-icon-img" src={day.day.condition.icon} />
        </div>
        <div className="daily-weather-temps">
          <span>
            H:&nbsp;{Math.round(day.day.maxtemp_f)}&deg;&nbsp;L:&nbsp;
            {Math.round(day.day.mintemp_f)}
            &deg;
          </span>
        </div>
        <div className="lg">
          <div className="precip-lg">
            <span className="text-color-main">
              <i className="bi bi-droplet"></i>&nbsp;
              {day.day.daily_chance_of_rain > day.day.daily_chance_of_snow
                ? day.day.daily_chance_of_rain
                : day.day.daily_chance_of_snow}
              %
            </span>
          </div>
        </div>
        <div className="daily-weather-details text-color-third">
          <div>
            Wind
            <span className="right text-color-main">
              {Math.round(day.day.maxwind_mph)} mph
            </span>
          </div>
          <div>
            Humidity
            <span className="right text-color-main">
              {day.day.avghumidity}%
            </span>
          </div>
          <div>
            Precip
            <span className="right text-color-main">
              <i className="bi bi-droplet"></i>&nbsp;
              {day.day.daily_chance_of_rain > day.day.daily_chance_of_snow
                ? day.day.daily_chance_of_rain
                : day.day.daily_chance_of_snow}
              %
            </span>
          </div>
          <div>
            Visibility
            <span className="right text-color-main">
              {day.day.avgvis_miles} mi{" "}
            </span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className="daily-weather">{renderedDays}</div>
    </React.Fragment>
  );
};

export default DailyWeather;
