import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import App from "./App";
import CurrentWeather from "./routes/CurrentWeather";
import HourlyWeather from "./routes/HourlyWeather";
import DailyWeather from "./routes/DailyWeather";
import Radar from "./routes/Radar";

import reducers from "./reducers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <CurrentWeather />,
      },
      {
        path: "hourly",
        element: <HourlyWeather />,
      },
      {
        path: "daily",
        element: <DailyWeather />,
      },
      {
        path: "radar",
        element: <Radar />,
      },
    ],
  },
]);

export const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
