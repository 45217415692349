import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const HourlyWeather = () => {
  let weatherData = useSelector((state) => state.weather);
  const [hourly, setHourly] = useState([]);

  useEffect(() => {
    if (weatherData != null) {
      setHourly(getHourly(100));
    }
  }, [weatherData]);

  const getHourly = (limit) => {
    if (weatherData == null || weatherData.location == null) return;

    let hourly = [];
    let now = new Date(weatherData.location.localtime.replace(/-/g, "/"));
    let count = 0;

    for (let [day, dayObj] of Object.entries(
      weatherData.forecast.forecastday
    )) {
      for (let [hour, hourObj] of Object.entries(dayObj.hour)) {
        if (count >= limit - 1) {
          break;
        }
        let hourDate = new Date(hourObj.time.replace(/-/g, "/"));
        if (hourDate > now) {
          hourly.push(hourObj);
          count++;
        }
      }
    }
    return hourly;
  };

  const renderedHourly = hourly.map((hour) => {
    let date = new Date(hour.time.replace(/-/g, "/"));
    return (
      <div className="hourly-weather-entry" key={hour.time_epoch}>
        <span className="day">{format(date, "EEE")}</span>
        <span className="text-time-hourly-weather">{format(date, "h a")}</span>
        <span className="hourly-icon">
          <img className="weather-icon" src={hour.condition.icon} />
        </span>
        <span className="hourly-weather-temp">
          {Math.round(hour.temp_f)}&deg;
        </span>
        <span className="hourly-weather-feelslike">
          <span className="small">FeelsLike&nbsp;</span>
          {Math.round(hour.feelslike_f)}&deg;
        </span>
        <span className="hourly-weather-precip">
          <i className="bi bi-droplet"></i>{" "}
          {hour.chance_of_rain > hour.chance_of_snow
            ? hour.chance_of_rain
            : hour.chance_of_snow}
          %
        </span>
      </div>
    );
  });
  return (
    <div className="hourly-weather">
      <div className="hourly-weather-flex-container">{renderedHourly}</div>
    </div>
  );
};

export default HourlyWeather;
