export const setGeo = (geo) => {
  return {
    type: "SET_GEO",
    payload: geo,
  };
};

export const setLocation = (location) => {
  return {
    type: "SET_LOCATION",
    payload: location,
  };
};

export const setZipCode = (zip) => {
  return {
    type: "SET_ZIP",
    payload: zip,
  };
};

export const setRadarSource = (radarSource) => {
  return {
    type: "SET_RADAR_SOURCE",
    payload: radarSource,
  };
};

export const setWeather = (weather) => {
  return {
    type: "SET_WEATHER",
    payload: weather,
  };
};
