import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Radar = () => {
  let radar = useSelector((state) => state.radar);

  return (
    <div className="radar">
      <iframe id="frame" frameBorder="0" src={radar}></iframe>
      <Link to="/">
        <div id="xdiv-radar">
          <div className="xdiv-radar">
            <div className="xd-radar"></div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Radar;
