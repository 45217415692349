import { Link } from "react-router-dom";

const BottomNav = () => {
  return (
    <div>
      <div className="bottom-nav">
        <div className="bottom-nav-container">
          <div className="house">
            <Link to={"/"}>
              <i className="bi bi-house"></i>
            </Link>
          </div>
          <div id="clock">
            <Link to={"hourly"}>
              <i className="bi bi-clock"></i>
            </Link>
          </div>
          <div id="calendar">
            <Link to={"daily"}>
              <i className="bi bi-calendar4"></i>
            </Link>
          </div>
          <div id="globe">
            <Link to={"radar"}>
              <i className="bi bi-globe"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
