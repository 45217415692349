import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import DailyWeather from "./DailyWeather";
import HourlyWeather from "./HourlyWeather";

const CurrentWeather = () => {
  let weatherData = useSelector((state) => state.weather);
  const [hourly, setHourly] = useState([]);
  const [hourlyMain, setHourlyMain] = useState([]);

  useEffect(() => {
    if (weatherData != null) {
      setHourly(getHourly(100));
      setHourlyMain(
        getHourly(Math.min(Math.floor(window.innerWidth / 70), 10))
      );
    }
  }, [weatherData]);

  const getHourly = (limit) => {
    if (
      weatherData == null ||
      weatherData.location == null ||
      weatherData.forecast == null
    )
      return;

    let hourly = [];
    let now = new Date(weatherData.location.localtime.replace(/-/g, "/"));
    let count = 0;

    for (let [day, dayObj] of Object.entries(
      weatherData.forecast.forecastday
    )) {
      for (let [hour, hourObj] of Object.entries(dayObj.hour)) {
        if (count >= limit - 1) {
          break;
        }
        let hourDate = new Date(hourObj.time.replace(/-/g, "/"));
        if (hourDate > now) {
          hourly.push(hourObj);
          count++;
        }
      }
    }
    return hourly;
  };

  const renderedHourly = hourlyMain.map((hour) => {
    let date = new Date(hour.time.replace(/-/g, "/"));
    return (
      <div className="hourly-banner-entry" key={hour.time_epoch}>
        <div className="text-time-hourly">{format(date, "h a")}</div>
        <div>
          <img className="weather-icon" src={hour.condition.icon} />
        </div>
        <div className="hourly-temp">{Math.round(hour.temp_f)}&deg;</div>
      </div>
    );
  });

  if (weatherData != null && hourly != null) {
    let date = new Date(weatherData.location.localtime.replace(/-/g, "/"));
    return (
      <React.Fragment>
        <div className="weather">
          <div className="current-weather text-color-main">
            <div className="current-weather-time sm">
              {format(date, "h:mm a")}
            </div>
            <div className="current-weather-icon">
              <img
                id="current-weather-icon-img"
                src={weatherData.current.condition.icon}
              />
            </div>
            <div id="current-temp">
              {Math.round(weatherData.current.temp_f)}&deg;
            </div>
            <div className="current-condition-text sm">
              {weatherData.current.condition.text}
            </div>
            <div className="radar-btn lg">
              <Link to={"radar"}>
                Live Radar <i className="bi bi-globe"></i>
              </Link>
            </div>
          </div>

          <div className="hourly-banner">
            <div className="hourly-banner-entry">
              <div className="text-time-hourly">Now</div>
              <div>
                <img
                  className="weather-icon"
                  src={weatherData.current.condition.icon}
                />
              </div>
              <div className="hourly-temp">
                {Math.round(weatherData.current.temp_f)}&deg;
              </div>
            </div>
            {renderedHourly}
          </div>

          <div className="current-weather-details text-color-third">
            <div className="current-weather-details-container">
              <div>
                Feels Like
                <span className="right text-color-main">
                  {Math.round(weatherData.current.feelslike_f)}&deg;
                </span>
              </div>
              <div>
                Wind
                <span className="right text-color-main">
                  {weatherData.current.wind_dir}{" "}
                  {Math.round(weatherData.current.wind_mph)} mph
                </span>
              </div>
              <div ng-if="weather.current.gust_mph > weather.current.wind_mph">
                Gusts
                <span className="right text-color-main">
                  {Math.round(weatherData.current.gust_mph)} mph
                </span>
              </div>
              <div>
                Pressure
                <span className="right text-color-main">
                  {weatherData.current.pressure_in} inHg
                </span>
              </div>
              <div>
                Humidity
                <span className="right text-color-main">
                  {weatherData.current.humidity}%
                </span>
              </div>
              <div>
                Clouds
                <span className="right text-color-main">
                  {weatherData.current.cloud}%
                </span>
              </div>
              <div>
                Visibility
                <span className="right text-color-main">
                  {weatherData.current.vis_miles} mi
                </span>
              </div>
              <div>
                UV
                <span className="right text-color-main">
                  {weatherData.current.uv}
                </span>
              </div>
            </div>
          </div>
          <div className="daily-weather-container lg">
            <DailyWeather
              days={weatherData.forecast.forecastday}
            ></DailyWeather>
          </div>
        </div>
        <div className="lg">
          <HourlyWeather hourly={hourly}></HourlyWeather>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default CurrentWeather;
